/************************************
*                                   *
*    --global-creamy: #fff5d1;      *
*    --global-blue-grey: #10222e;   *
*    --global-orange: #f55138;      *
*    --global-succes: #32CD32;      *
*    --global-alert: #C81E1E;       *
*                                   *
*************************************/

#page-body-contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 70%;
  max-width: 1280px;
  margin: 0px auto;
  padding: 30px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  row-gap: 15px;

  min-width: 50%;
  padding: 40px;
  background-color: var(--global-orange);
  border-radius: 20px;

  box-shadow: 0px 0px 10px rgba(201, 201, 201, 0.5);
}

.form-title {
  margin-bottom: 20px;

  color: white;
  font-size: 30px;
  font-weight: 400;
  text-transform: uppercase;
}

.form-input-text,
.form-input-textarea {
  margin-bottom: 10px;
  padding: 15px;
  border: none;
  border-radius: 15px;

  color: var(--global-blue-grey);
  font-size: 18px;

  outline: none;
}

.form-input-text::placeholder,
.form-input-textarea::placeholder {
  color: #aaa;
  font-size: 18px;
}

.form-input-textarea {
  height: 100px;
}

.form-input-text:focus,
.form-input-textarea:focus {
  background-color: var(--global-creamy);
}

.form-submit-btn {
  width: 50%;
  margin-top: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 15px;

  background-color: white;
  color: #aaa;
  font-size: 18px;
  cursor: pointer;
}

.form-submit-btn:hover {
  background-color: var(--global-success);
  color: white;
  scale: 1.1;
  transition: 600ms;
}

@media screen and (max-width: 480px) {
  #page-body-contact {
    width: 100%;
    padding: 0;
  }

  .contact-form {
    width: 100%;
    padding: 20px;
    border-radius: 0;

    box-shadow: none;
  }
}