/************************************
*                                   *
*    --global-creamy: #fff5d1;      *
*    --global-blue-grey: #10222e;   *
*    --global-orange: #f55138;      *
*    --global-succes: #32CD32;      *
*    --global-alert: #C81E1E;       *
*                                   *
*************************************/

#computer-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  flex: 1;
  column-gap: 30px;
  row-gap: 30px;

  padding-left: 30px;
}

#show-filter-btn {
  display: none;

  width: 100%;
  height: 50px;
  border: none;
  border-radius: 15px;

  color: var(--global-creamy);
  background-color: var(--global-orange);
  cursor: pointer;
}

#show-filter-btn:hover {
  background-color: var(--global-success);
  scale: 1.1;
  transition: 600ms;
}

#scroll-top-list-btn {
  position: fixed;
  bottom: 10px;
  right: 15px;

  width: fit-content;
  height: fit-content;
  margin: 0;
  padding: 10px 10px 5px 10px;
  border: none;
  border-radius: 10px;

  color: var(--global-creamy);
  background-color: var(--global-success);

  cursor: pointer;
}

#scroll-top-list-btn:hover {
  scale: 1.1;
  transition: 600ms;
}

.bg-overlay {
  display: none;
}

.center-center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

@media screen and (max-width: 1024px) {
  #computer-list {
    justify-content: center;
  }
}

@media screen and (max-width: 640px) {
  #computer-list {
    padding: 30px 15%;
  }

  #show-filter-btn {
    display: block;
  }

  .bg-overlay {
    position: fixed;
    top: 16vh;
    left: 0;

    display: block;
    width: 100%;
    height: 100%;

    background-color: black;
    opacity: 0.8;
    z-index: 2;
    cursor: default;
  }
}

@media screen and (max-width: 480px) {
  #computer-list {
    padding: 30px 5%;
  }
}

@media screen and (max-width: 360px) {
  #computer-list {
    padding: 30px 2%;
  }
}