/* DEFAULT STYLES */

:root {
  --global-creamy: #fff5d1;
  --global-blue-grey: #10222e;
  --global-orange: #f55138;
  --global-success: #32CD32;
  --global-alert: #C81E1E;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  font-size: 16px;
  word-spacing: 2px;
  letter-spacing: 1.5px;
  font-family: 'Barlow', sans-serif;
}

html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  background-color: var(--global-blue-grey);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}


/* DEFAULT PAGES LAYOUT */
.page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  min-height: 53vh;
  margin: 0 auto;
  padding: 30px;
}

.page-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

/* CUSTOM SCROLLBAR */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: white;
}

::-webkit-scrollbar-thumb {
  background-color: var(--global-success);
}

.empty-result {
  font-size: 30px;
  font-weight: 600;
  color: var(--global-creamy);
}